import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function AboutPage() {
  return (
    <Layout>
      <SEO
        title="About"
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      />

      <section className="items-center">
      <h1 className="text-5xl">About</h1>

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
incididunt ut labore et dolore magna aliqua. Nisi quis eleifend quam
adipiscing vitae proin. Quis ipsum suspendisse ultrices gravida dictum.
Ultrices sagittis orci a scelerisque purus. Ut placerat orci nulla
pellentesque dignissim enim. Diam maecenas ultricies mi eget mauris. Lacus
luctus accumsan tortor posuere ac ut consequat semper viverra. Odio
pellentesque diam volutpat commodo sed egestas egestas. In ornare quam viverra
orci sagittis eu volutpat odio facilisis. Eros in cursus turpis massa
tincidunt dui. In vitae turpis massa sed. Sapien et ligula ullamcorper
malesuada proin libero nunc. Viverra tellus in hac habitasse platea dictumst
vestibulum rhoncus. Turpis massa tincidunt dui ut. Malesuada bibendum arcu
vitae elementum. Integer malesuada nunc vel risus. Neque egestas congue
quisque egestas diam. Pellentesque diam volutpat commodo sed egestas egestas
fringilla. Cursus in hac habitasse platea dictumst quisque sagittis purus sit.
Nulla pellentesque dignissim enim sit amet venenatis urna cursus eget.

Cursus sit amet dictum sit. Nulla aliquet enim tortor at auctor urna. Ut
pharetra sit amet aliquam id diam maecenas ultricies. Maecenas volutpat
blandit aliquam etiam erat velit scelerisque in dictum. Eleifend mi in nulla
posuere sollicitudin aliquam. Lorem ipsum dolor sit amet consectetur
adipiscing elit duis. Faucibus purus in massa tempor nec. Dolor magna eget est
lorem ipsum dolor sit amet. Sit amet cursus sit amet. Sed id semper risus in
hendrerit gravida rutrum quisque. At tellus at urna condimentum mattis
pellentesque id nibh. Sed libero enim sed faucibus turpis in eu. Placerat
vestibulum lectus mauris ultrices eros in cursus turpis massa.

Arcu vitae elementum curabitur vitae nunc sed velit. Molestie a iaculis at
erat pellentesque. Varius morbi enim nunc faucibus a pellentesque. Donec
ultrices tincidunt arcu non sodales neque sodales ut. Morbi tincidunt ornare
massa eget egestas purus viverra. Nec feugiat in fermentum posuere urna. Augue
lacus viverra vitae congue eu consequat ac felis donec. Quis imperdiet massa
tincidunt nunc. Suspendisse faucibus interdum posuere lorem. Facilisis leo vel
fringilla est ullamcorper. Faucibus a pellentesque sit amet porttitor. Congue
nisi vitae suscipit tellus mauris a diam maecenas sed. Arcu cursus euismod
quis viverra nibh cras pulvinar mattis nunc. Ornare aenean euismod elementum
nisi quis eleifend. Diam maecenas sed enim ut sem viverra aliquet eget.
Ultrices tincidunt arcu non sodales neque sodales ut etiam sit. Cursus metus
aliquam eleifend mi in nulla posuere sollicitudin. Nisl suscipit adipiscing
bibendum est ultricies integer.

At quis risus sed vulputate odio ut enim blandit volutpat. Diam phasellus
vestibulum lorem sed risus ultricies tristique nulla aliquet. Turpis cursus in
hac habitasse platea dictumst quisque sagittis purus. Ipsum a arcu cursus
vitae congue mauris. Rhoncus urna neque viverra justo nec ultrices dui sapien
eget. Laoreet id donec ultrices tincidunt arcu. Morbi quis commodo odio aenean
sed adipiscing diam donec adipiscing. Suspendisse faucibus interdum posuere
lorem ipsum dolor. Magna fermentum iaculis eu non diam phasellus. Velit ut
tortor pretium viverra suspendisse. Nisl nisi scelerisque eu ultrices vitae.
Massa placerat duis ultricies lacus. Sed cras ornare arcu dui vivamus arcu
felis bibendum ut. Mi sit amet mauris commodo. Sit amet tellus cras
adipiscing. Faucibus vitae aliquet nec ullamcorper sit amet risus. Et odio
pellentesque diam volutpat commodo sed egestas. Eget magna fermentum iaculis
eu non diam. Diam sit amet nisl suscipit adipiscing bibendum est ultricies
integer. Mauris cursus mattis molestie a.

Vel eros donec ac odio tempor. Praesent elementum facilisis leo vel. Faucibus
vitae aliquet nec ullamcorper. Nunc mattis enim ut tellus elementum sagittis.
Sollicitudin nibh sit amet commodo nulla facilisi. Tellus at urna condimentum
mattis. Tellus integer feugiat scelerisque varius morbi enim nunc faucibus.
Diam ut venenatis tellus in. Lectus urna duis convallis convallis tellus id
interdum. Feugiat in fermentum posuere urna nec tincidunt praesent semper. Sit
amet volutpat consequat mauris nunc congue nisi. Arcu dictum varius duis at
consectetur. Aenean vel elit scelerisque mauris. Cursus mattis molestie a
iaculis at erat. Amet cursus sit amet dictum. Mattis nunc sed blandit libero
volutpat. A pellentesque sit amet porttitor eget dolor. Nec nam aliquam sem et
tortor consequat id porta.

Mauris ultrices eros in cursus turpis. Luctus accumsan tortor posuere ac ut
consequat. Sit amet commodo nulla facilisi nullam. At urna condimentum mattis
pellentesque id. Ultrices tincidunt arcu non sodales neque sodales. Tristique
et egestas quis ipsum suspendisse ultrices gravida. Neque vitae tempus quam
pellentesque nec nam aliquam sem et. Ut lectus arcu bibendum at varius vel
pharetra. Viverra nibh cras pulvinar mattis nunc sed blandit libero. Aenean
sed adipiscing diam donec adipiscing tristique risus. Elementum nisi quis
eleifend quam. Felis eget nunc lobortis mattis aliquam faucibus.

Ac felis donec et odio pellentesque diam volutpat commodo sed. Aliquam
malesuada bibendum arcu vitae elementum curabitur. Libero nunc consequat
interdum varius sit. Sit amet massa vitae tortor condimentum lacinia quis.
Ante metus dictum at tempor commodo ullamcorper a. Egestas sed sed risus
pretium quam vulputate dignissim suspendisse. Mollis nunc sed id semper risus
in hendrerit gravida. Urna nunc id cursus metus aliquam eleifend mi in nulla.
Varius quam quisque id diam. Consectetur adipiscing elit duis tristique
sollicitudin nibh. Volutpat ac tincidunt vitae semper quis lectus nulla at
volutpat. Proin sed libero enim sed faucibus turpis. Sem viverra aliquet eget
sit amet tellus cras adipiscing. Mauris sit amet massa vitae. Semper eget duis
at tellus at urna condimentum mattis pellentesque. Mauris pharetra et ultrices
neque ornare aenean euismod elementum nisi. Diam in arcu cursus euismod quis
viverra nibh. Curabitur gravida arcu ac tortor dignissim. Dignissim
suspendisse in est ante in nibh mauris cursus mattis. Adipiscing at in tellus
integer feugiat scelerisque varius morbi.

Mauris commodo quis imperdiet massa. In ante metus dictum at. Adipiscing vitae
proin sagittis nisl rhoncus mattis rhoncus urna. Nisl pretium fusce id velit
ut tortor. Eget arcu dictum varius duis at. Nibh nisl condimentum id venenatis
a condimentum vitae sapien. Bibendum at varius vel pharetra vel turpis nunc
eget lorem. Aliquet nec ullamcorper sit amet. Id venenatis a condimentum vitae
sapien. Est velit egestas dui id. Vehicula ipsum a arcu cursus vitae congue
mauris rhoncus. Nunc sed blandit libero volutpat sed cras ornare arcu dui.
Suspendisse in est ante in nibh mauris cursus.

Ut sem viverra aliquet eget sit amet tellus cras. Venenatis a condimentum
vitae sapien pellentesque habitant morbi. Adipiscing vitae proin sagittis nisl
rhoncus mattis rhoncus. Curabitur vitae nunc sed velit dignissim. Sollicitudin
nibh sit amet commodo nulla facilisi nullam vehicula ipsum. Mattis vulputate
enim nulla aliquet. Dui faucibus in ornare quam viverra orci sagittis eu
volutpat. Orci nulla pellentesque dignissim enim sit amet. Tempus quam
pellentesque nec nam aliquam sem et tortor. At urna condimentum mattis
pellentesque. Turpis egestas sed tempus urna et pharetra pharetra massa massa.
Nullam non nisi est sit amet. Vitae purus faucibus ornare suspendisse sed.
Eget est lorem ipsum dolor sit amet. A cras semper auctor neque vitae tempus
quam pellentesque nec. Vel facilisis volutpat est velit egestas dui id ornare.
Massa placerat duis ultricies lacus sed turpis. Ullamcorper velit sed
ullamcorper morbi tincidunt. Cursus mattis molestie a iaculis at erat
pellentesque adipiscing. Nisl nisi scelerisque eu ultrices vitae.

Est sit amet facilisis magna etiam tempor orci eu lobortis. Sed felis eget
velit aliquet sagittis id consectetur purus ut. Mauris commodo quis imperdiet
massa tincidunt nunc. A iaculis at erat pellentesque adipiscing. Risus
ultricies tristique nulla aliquet enim tortor at auctor. Sed felis eget velit
aliquet sagittis id consectetur. Elit ut aliquam purus sit. Eu turpis egestas
pretium aenean. Suspendisse potenti nullam ac tortor vitae purus faucibus
ornare suspendisse. Tortor consequat id porta nibh venenatis cras. Blandit
volutpat maecenas volutpat blandit aliquam etiam erat. Donec enim diam
vulputate ut pharetra sit. Magna eget est lorem ipsum dolor. Eu non diam
phasellus vestibulum lorem sed risus ultricies. Ut consequat semper viverra
nam libero justo laoreet sit. Malesuada pellentesque elit eget gravida. Nec
d
      </section>
    </Layout>
  );
}

export default AboutPage;
